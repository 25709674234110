<template>
    <div class="discount-wrapper">
        <h2 class="mb-10">{{ $t('discounts.createLabel') }}</h2>
        <!-- <p class="help-text">{{ $t('discounts.expirationHelpText') }}</p> -->
        <div class="discounts-container">
            <CRTag
                class="tag"
                v-for="code in discountCodes"
                :key="code.id"
                :type="(isExpire(code.expireAt) ? 'danger2' : 'additional')"
                delete-allowed
                :edit-allowed="!code.isOneTimeUse || !code.isUsed"
                svg-color="#00595A"
                @delete="handleDelete(code)"
                @edit="handleEdit(code)"
            >
                <div>{{ `${code.code}` }}</div>
                <div> {{ `${code.amount}${getSign(code.type)}` }} <b class="discount_usage">{{ code.usage }}</b> {{ `(${(code.expireAt === null ? 'Visa laiką' : code.expireAt)})`}}</div>
                {{ getSingleUseText(code) }} <span class="color-danger">{{ getIsCodeUsedText(code) }}</span>
            </CRTag>
        </div>
        <div class="my-15">
            <p class="help-text">{{ $t('discounts.mealKits') }}</p>
            <div v-for="mealKit in mealKits"  :key="mealKit.id">
                <CRCheckbox
                    class="mb-10"
                    :checked="isMealkitIncludes(mealKit.id, null)"
                    @change="mealKitSelectionHandler(mealKit, null)"
                >
                    {{ $filters.getTranslationByKey(mealKit.name) }}
                </CRCheckbox>
                <div class="my-15 periods">
                    <div class="period"  v-for="period in mealKit.periods" :key="period.id">
                        <CRCheckbox
                            class="mb-10"
                            :checked="isMealkitIncludes(mealKit.id, period.id)"
                            @change="mealKitSelectionHandler(mealKit, period.id)"
                        >
                            {{ $filters.getTranslationByKey(period.name) }}
                        </CRCheckbox>
                    </div>
                </div>
            </div>
        </div>
        <CRInput
            class="mb-25 mt-20"
            v-model="inputsModel.code"
            :label="$t('discounts.code')"
            :placeholder="$t('discounts.code')"
            :errors="errors?.code"
            @onChange="$filters.clearError(errors, 'code')"
        />
        <div class="type-buttons">
            <CRButton
                :active="button.type === inputsModel.type"
                size="x-small"
                v-for="button in typeButtons"
                :key="button.id"
                pattern="section"
                @click="inputsModel.type = button.type"
            >
                {{ button.name }}
            </CRButton>
        </div>
        <CRInput
            type="number"
            class="mb-25"
            v-model="inputsModel.amount"
            :label="inputLabel"
            :placeholder="inputLabel"
            :errors="errors?.amount"
            @onChange="$filters.clearError(errors, 'amount')"
        />
        <CRInput
            type="date"
            class="mb-25"
            v-model="inputsModel.expireAt"
            :label="'Galiojimas'"
            :placeholder="'Galiojimas'"
            :errors="errors?.expireAt"
            @onChange="$filters.clearError(errors, 'expireAt')"
        />

        <CRCheckbox v-model:checked="inputsModel.isOneTimeUse" class="mb-10"> Vienkartinio naudojimo </CRCheckbox>
        <CRCheckbox v-model:checked="inputsModel.isFreeDelivery" class="mb-30"> Nemokamas pristatymas </CRCheckbox>

        <CRButton :disabled="buttonDisabled" @click="createDiscountCode">
            {{ buttonLabel }}
        </CRButton>
        <div class="mt-30">
            <h2 class="mb-10">{{ $t('discounts.multiple') }}</h2>
            <p class="help-text">{{ $t('discounts.multipleInSingleOrder') }}</p>
            <CRToggle checked="true">{{ $t('discounts.multiple') }}</CRToggle>
        </div>
    </div>
</template>

<script>
    import { typeButtons } from '@/config/discounts/discount';
    import CRTag from '@/components/Common/Tags/CRTag';
    import CRInput from '@/components/Common/Inputs/CRInput';
    import CRButton from '@/components/Common/Buttons/CRButton';
    import CRToggle from '@/components/Common/Buttons/CRToggle.vue';
    import { DiscountCodeApi, MealKitApi } from '@/api';
    import CRCheckbox from '@/components/Common/Checkbox/CRCheckbox';
    import { TYPE } from 'vue-toastification';
    import { cloneDeep } from 'lodash';
    import { getSign } from '@/helpers/SignHelper';
    import { DiscountTypeEnum } from '@/utils/enums';
    import moment from 'moment';

    const DEFAULT_CODE_FORM = {
        code: '',
        amount: 0,
        expireAt: null,
        type: DiscountTypeEnum.PERCENTAGE,
        isOneTimeUse: false,
        isFreeDelivery: false,
        mealKitsIds: [],
    };

    export default {
        name: 'DiscountCodesSetting',
        components: { CRCheckbox, CRButton, CRInput, CRToggle, CRTag },

        data() {
            return {
                getSign,
                typeButtons,
                discountCodes: [],
                mealKits: [],
                form: cloneDeep(DEFAULT_CODE_FORM),
                editedCode: null,
                errors: {},
            };
        },
        computed: {
            inputsModel() {
                return this.editedCode ?? this.form;
            },

            inputLabel() {
                return this.form.type === DiscountTypeEnum.PERCENTAGE
                    ? this.$t('discounts.amount')
                    : this.$t('discounts.amountFixed');
            },

            buttonLabel() {
                return this.editedCode ? this.$t('buttons.edit') : this.$t('buttons.create');
            },

            buttonDisabled() {
                return !this.inputsModel.code || !this.inputsModel.mealKitsIds.length;
            },
        },
        methods: {
            isExpire(date) {
                if(date === null) {
                    return false;
                } else {
                    return moment(date) < moment.now();
                }
            },
            isMealkitIncludes(mealKit, period) {
                let includesMealkit = this.inputsModel.mealKitsIds.findIndex((amealKit) => amealKit.id === mealKit)
                if(includesMealkit === -1) {
                    return false;
                } else {
                    if(period === null) {
                        return true;
                    } else {
                        console.log(this.inputsModel.mealKitsIds)
                        let includesPeriod = this.inputsModel.mealKitsIds[includesMealkit].periods.indexOf(period)
                        if(includesPeriod === -1) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                }
            },
            getSingleUseText(code) {
                return code.isOneTimeUse ? 'Single use' : '';
            },

            getIsCodeUsedText(code) {
                return code.isOneTimeUse && code.isUsed ? 'USED' : '';
            },

            async fetchCodes() {
                try {
                    const response = await DiscountCodeApi.getAll();
                    this.discountCodes = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            handleEdit(code) {
                this.editedCode = cloneDeep(code);
            },

            async handleDelete(code) {
                try {
                    await DiscountCodeApi.destroy(code.id);

                    this.$filters.toast(code.code + this.$t('otherSettings.deleted'), { type: TYPE.SUCCESS });

                    this.discountCodes = this.discountCodes.filter((discountCode) => discountCode.id !== code.id);

                    if (this.editedCode?.id === code.id) {
                        this.editedCode = null;
                    }
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            async createDiscountCode() {
                try {
                    if (this.editedCode) {
                        const response = await DiscountCodeApi.update(this.editedCode.id, this.editedCode);

                        this.$filters.toast(response.data.code + this.$t('otherSettings.updated'), {
                            type: TYPE.SUCCESS,
                        });

                        const neededCodeIndex = this.discountCodes.findIndex((code) => code.id === this.editedCode.id);
                        this.discountCodes[neededCodeIndex] = response.data;

                        this.editedCode = null;
                    } else {
                        const response = await DiscountCodeApi.store(this.form);

                        this.$filters.toast(response.data.code + this.$t('otherSettings.created'), {
                            type: TYPE.SUCCESS,
                        });

                        this.discountCodes.push(response.data);
                        this.form = cloneDeep(DEFAULT_CODE_FORM);
                    }
                } catch (error) {
                    this.errors = error.errors;
                }
            },

            async fetchMealKits() {
                try {
                    const response = await MealKitApi.getAll();
                    this.mealKits = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },

            mealKitSelectionHandler(mealKit, periodId) {
                let includesMealKit = this.inputsModel.mealKitsIds.findIndex(existingMealKit => existingMealKit.id === mealKit.id);

                if (includesMealKit === -1 && periodId === null) {
                    // If mealKit doesn't exist and periodId is not null, add a new entry
                    const periodIds = mealKit.periods.map(period => period.id);
                    this.inputsModel.mealKitsIds.push({
                        id: mealKit.id,
                        periods: periodIds
                    });
                } else if (includesMealKit !== -1) {
                    // If mealKit exists, handle periodId
                    if (periodId === null) {
                        // If periodId is null, remove the entire mealKit entry
                        this.inputsModel.mealKitsIds.splice(includesMealKit, 1);
                    } else {
                        // If periodId is not null, handle adding or removing the period
                        let includesPeriod = this.inputsModel.mealKitsIds[includesMealKit].periods.indexOf(periodId);

                        if (includesPeriod === -1) {
                            // If periodId is not in the array, add it
                            this.inputsModel.mealKitsIds[includesMealKit].periods.push(periodId);
                        } else {
                            // If periodId is in the array, remove it
                            this.inputsModel.mealKitsIds[includesMealKit].periods.splice(includesPeriod, 1);
                        }
                    }
                } else {
                    this.inputsModel.mealKitsIds.push({
                        id: mealKit.id,
                        periods: [periodId],
                    });
                }
            },
        },
        async mounted() {
            await this.fetchCodes();
            await this.fetchMealKits();
        },
    };
</script>

<style scoped lang="scss">
    .periods {
        margin-left: 20px !important;
    }

    .discount_usage {
        color: #ff6100;
    }
    .discount-wrapper {
        @include column-align-start-justify-start;

        .help-text {
            font-style: italic;
            margin-bottom: 15px;
        }

        .discounts-container {
            @include row-align-start;
            flex-wrap: wrap;

            .tag {
                margin: 5px 8px 8px 0;
            }
        }

        .type-buttons {
            @include row-align-center;

            * {
                margin: 0 8px 20px 0;
            }
        }
    }
</style>
