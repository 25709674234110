import { translation } from '@/helpers/TranslationHelper';
import { DiscountTypeEnum } from '@/utils/enums';

export const typeButtons = [
    {
        type: DiscountTypeEnum.PERCENTAGE,
        name: translation('discounts.percentage'),
    },
    {
        type: DiscountTypeEnum.FIXED,
        name: translation('discounts.fixed'),
    },
];
